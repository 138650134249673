import React from "react";
import { Box, Button, Typography } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

function ThankYou() {
  const handleBackToMain = () => {
    window.location.href = "https://pohahouse.com/marketplace-verification/";
  };

  return (
    <Box
      sx={{ textAlign: "center", p: 4, backgroundColor: "#EBE5D7", flex: 1 }}
    >
      <CheckCircleOutlineIcon sx={{ fontSize: 60, color: "green" }} />
      <Typography variant="h5" sx={{ my: 2 }}>
        Thank you for verifying your POHA House application with rentcard!
      </Typography>
      <Typography variant="body1" sx={{ mt: 1 }}>
        We received your data and will review it within 24 hours.
      </Typography>
      <Typography variant="body1" sx={{ mt: 1 }}>
        You will then receive an update from us via mail.
      </Typography>
      <Typography variant="body1" sx={{ mt: 1 }}>
        If you have any questions in the meantime, do not hesitate to reach out
        via <a href="mailto:hey@pohahouse.com">hey@pohahouse.com</a>
      </Typography>

      <Button
        variant="contained"
        sx={{
          mt: 3,
          color: "#363638",
          position: "relative",
          backgroundColor: "#FFE217", // Yellow background
          overflow: "hidden",
          textTransform: "none",
          boxShadow: "none",
          borderRadius: 0,
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            width: 0,
            height: "100%",
            transition: "width 0.4s ease-out",
            zIndex: 0, // Ensure overlay is above background but below content
          },
          "&:hover": {
            backgroundColor: "#FFFFFF", // Yellow background
            border: "2px solid #FFE217",
            boxShadow: "none",
          },
        }}
        onClick={handleBackToMain}
      >
        Get some POHA Add-ons
      </Button>
    </Box>
  );
}

export default ThankYou;
