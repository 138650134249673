import React, {useEffect, useState, useRef} from 'react';
import { useNavigate } from 'react-router-dom';



function IframeContainer() {
  const navigate = useNavigate();
  const ref = useRef(null);
  const [height, setHeight] = useState(window.innerHeight - 195);
  const [mainCompleted, setMainCompleted] = useState(false);
  const [iframeSrc, setIframeSrc] = useState('');

  /* useEffect(() => {
    if (!new URLSearchParams(window.location.search).get('user')) {
      return 'https://www.pohahouse.com'; // Fallback URL
    }
  }); */

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const userParam = queryParams.get('user');
    if (!userParam) {
      window.location.href = 'https://www.pohahouse.com';
      return null;
    }
    else {
      const constructIframeSrc = () => {
        // Assuming you are using window.location.search to get the 'user' query parameter
        
        const preUserParam = queryParams.get('preUserOneTimeToken');
  
        try {
          // Decode and parse the user JSON object from the URL
          const userObject = JSON.parse(decodeURIComponent(userParam));
  
          // Construct the src URL
          const userJson = encodeURIComponent(JSON.stringify(userObject));
          return `https://my.rentcard.app/registration/personal-details?preUserOneTimeToken=${preUserParam}&user=${userJson}&version=1.0.2`;
        } catch (error) {
          console.error('Error parsing user parameter:', error);
          return 'https://my.rentcard.app/registration'; // Fallback URL in case of error
        }
      };
  
      setIframeSrc(constructIframeSrc());
    }
    
  }, []);

  useEffect(() => {
    const messageHandler = (event) => {
      if (event.type === 'message'){
        if (Array.isArray(event.data) && event.data.length === 2 && event.data[0] === 'height') {
          // This is for height changes, based on messages from the iFrame
          const newHeight = event.data[1] + 6;
          if (newHeight < 600) {
            setHeight(600);
          } else {
            setHeight(newHeight);
          }
        }
        else if (Array.isArray(event.data) && event.data.length === 2 && event.data[0] === 'done') {
          console.log("setting token");
          const mainAppToken = event.data[1];
          window.localStorage.setItem('mainAppToken', mainAppToken);
          setMainCompleted(true);
        }
      }
      
    };
    
    // Add the event listener
    window.addEventListener("message", messageHandler);

    // Cleanup on unmount
    return () => {
        window.removeEventListener("message", messageHandler);
    };
    
    
  }, []);



  useEffect(() => {
    if (mainCompleted) {
      navigate('/thankyou');
    }
  }, [mainCompleted, navigate]);

  if (mainCompleted) {
    return null; 
  }

  return (
    <div style={{ backgroundColor: '#EBE5D7', textAlign: 'center' }} >
        <iframe
          className='bg-transparent'
          title='frame'
          ref={ref}
          id="myIframe"
          height={height.toString()}
          src={iframeSrc}
          style={{ width: '100%', maxWidth: '1200px', overflowY:"hidden", border:"none" }}
        />
    </div>
  );
}

export default IframeContainer;