import React from 'react';
import { Box, Grid, Link } from '@mui/material';

function Footer() {
  const footerLinks = [
    { label: "Terms of Use", url: "https://my.pohahouse.com/#!/go/view/app/conditions/conditions?view=terms" },
    { label: "Privacy Policy", url: "https://my.pohahouse.com/#!/go/view/app/conditions/conditions?view=privacy" },
    { label: "Cookie Policy", url: "https://my.pohahouse.com/#!/go/view/app/conditions/conditions?view=cookies" },
    { label: "Imprint", url: "https://pohahouse.com/imprint/" },
    { label: "About Us", url: "https://my.pohahouse.com/#!/go/view/app/about-us/about-us" }
  ];

  
  return (
    <Box sx={{
      backgroundColor: '#363638',
      color: 'white',
      padding: '20px',
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 1000 // Ensures the footer stays above other elements
    }}>
      <Grid container spacing={2} justifyContent="center">
        {footerLinks.map((link, index) => (
          <Grid item xs={12} sm={2.4} key={index}> {/* Adjust sm value according to your layout preference */}
            <Link href={link.url} color="inherit" target="_blank" underline="none" style={{ padding: '5px', display: 'block', textAlign: 'center' }}>
              {link.label}
            </Link>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default Footer;